html, body {
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

body {
  background: radial-gradient(circle at 80% 80%, #18657f, #073646);
  color: white;
  transition: background .5s;
}

    body.noScroll { /* ...or body.dialogShowing */
        overflow: hidden;
    }

#root {
    height: 100%;
}

.rootLayout {
    overflow-y: auto;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
}

#customBackground {
    transition: opacity 1s 2s; /* 1s of delay to ensure image is loaded */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0;
    grid-row: 1/3;
    grid-column: 1/2;
}

.globalContainer {
    height: 100%;
    grid-row: 1/2;
    grid-column: 1/2;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.logoContainer {
    grid-row: 2/3;
    grid-column: 1/2;
    margin: 10px 10px 30px 30px;
    display: block;
}

.logoFull {
    height: 33px;
    display: none;
}

.logoSmall {
    height: 33px;
    display: block;
}

@media (min-width: 600px) {
    .logoFull {
        display: block !important;
    }

    .logoSmall {
        display: none;
    }
}

.themedButton:hover:not([disabled]),
.themedButton:focus:not([disabled]) {
    box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.15), 0 0 8px 0 rgba(255, 255, 255, 0.4);
}

.cduxLogo path {
    fill: none;
    fill-opacity: 1;
    stroke: #ffffff;
    stroke-width: 12;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
}

/* override bootstap's value : 0.375rem 0.75rem */
.btn {
    padding: 0.375rem 1rem;
}

.hide-when-super-small {
    display: none;
}

@media (min-height: 580px) and (min-width: 580px) {
    .hide-when-super-small {
        display: block !important;
    }
}

@media (max-height: 400px) {
    .logoContainer {
        display: none !important;
    }
}

.versionNumber {
    position: fixed;
    right: 10px;
    bottom: 10px;
    color: white;
    opacity: 0.3;
    margin: 0;
    font-size: 10px;
}

h2 {
    margin-bottom: 5vh;
    font-size: 8vw;
}

@media (min-width: 400px) {
    h2 {
        font-size: 2rem;
    }
}

.inlineInput {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 10px;
    justify-content: center;
    justify-items: center;
}

@media (min-width: 350px) {
    .inlineInput {
        grid-template-columns: auto auto;
        grid-template-rows: auto;
    }
}

.spinner {
    margin: 20px;
}

.error-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
}

.error-icon {
    font-size: 70px;
}