.presenter-title {
    width: 100%;
    position: absolute;
    margin: 0px -15px 0px -15px;
    top: 0px;
    left: 15px;
    z-index: 0;
}

.main-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto;
    margin: 0px -15px 0px -15px;
}

.loading {
    display: flex;
    grid-area: 1 / 1 / 3 / 4;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.bottom {
    margin-bottom: 10px;
    grid-area:2/2/3/3;
    z-index:1;
}

.nav-bar button {
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0px 5px 0px 5px;
    font-size: x-small;
    padding:initial;
}

button:focus{
    outline: none;
}

button:disabled {
    opacity:0.3;
}

.zppd-container {
    grid-area: 1 / 1 / 3 / 4;
}

.zppd {
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.zppdc-img {
    grid-column: 1/2; 
    grid-row: 1/2;
    object-fit: contain;
}

.zppdc-drawing {
    grid-column: 1/2;
    grid-row: 1/2;
    pointer-events: all;
    margin: auto;
}

/*specific rules for desktop*/
@media only screen and (min-width: 992px){
    .not-display-if-desktop {
        display: none;
    }
}

/*specific rules for mobile and tablet*/
@media only screen and (max-width: 992px) {
    .not-display-if-mobile {
        display: none;
    }

}
