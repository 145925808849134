@media (min-width: 500px) and (max-width: 800px){
    .voteContainer {
        width: 40%;
        height: 40%;
        max-height: 40%;
        max-width: 40%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 34vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .wrapper > div {
        flex: 1 1 50%;
    }
}

@media (min-width: 800px) {
    .voteContainer {
        width: 20%;
        height: 20%;
        max-height: 20%;
        max-width: 20%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 17vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .wrapper > div {
            flex: 1 1 25%;
        }
}

@media (max-width: 500px) {
    .voteContainer {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        position: relative;
        margin: 20px 20px;
        align-self: center;
    }

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 68vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .wrapper > div {
            flex: 1 1 100%;
        }
}

.voteContainer:hover {
    transition: all ease 0.3s;
    transform: translateY(-8px);
}

.voteContainer:hover > :last-child:not(.clicked) {
    opacity: 0.5;
}

.clicked {
    transition: all ease 0.3s;
    opacity: 1 !important;
}

.check {
    position: absolute;
    transform : translate(-50%, -50%);
    opacity: 0;
}
