.noteContainer {
    width: 70vmin;
    height: 70vmin;
    max-height: 300px;
    max-width: 300px;

    position: relative;
    margin: 20px auto;
    box-shadow: 0 5px 20px 0 hsla(0, 0%, 0%, 0.5);
}

.stickedContainer {
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: text;
    border-radius: 5%;
}

.note {
    max-height: 100%;
    min-height: 20px;
    box-sizing: border-box;
    padding: 5%;

    vertical-align: middle;
    background: transparent;
    outline: none;
    overflow: hidden;
    border: none;
    resize: none;

    color: black;
    text-align: center;
    font-family: 'Segoe Print', 'Kalam', cursive;
    font-weight: 100;
    /*font-size: min(7vmin, 30px); would be better but too recent for now :'( */
    font-size: 7vmin
}

/* note will start resizing at 428px size : 428px * 70vmin = 300px */
@media (min-height: 428px) and (min-width: 428px) {
    .note {
        font-size: 30px;
    }
}


/*** Animations inspired from 'animate.css' eponym ones : https://animate.style/ ***/

@keyframes backOutTop {
    0% {
        transform: scale(1);
        opacity: 1
    }

    40% {
        transform: translateY(0) scale(.7);
        opacity: .7
    }

    to {
        transform: translateY(-200px) scale(.7);
        opacity: 0
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(.3)
    }

    50% {
        opacity: 1
    }
}
