
.dropZone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 4px;
    border-style: solid;
    background-color: #00000044;
    color: white;
    outline: none;
    transition: border .6s ease-in-out, background .4s ease-in-out;
    margin: 20px;
    cursor: pointer;
    max-width: 95vw;
}
    @media (min-width:800px) {
        .dropZone {
            max-width: 800px;
        }
    }

    .dropZone:hover {
        background-color: #00000022;
    }

    .dropZone p {
        user-select: none;
        margin: 0;
    }

.inputError {
    margin: 15px 0;
    color: #ffb315;
    animation: 1s linear 3s running forwards disappear;
}

.fileInfos {
    display: flex;
    align-items: center;
    max-width: 100%;
}
    .fileInfos p {
        margin: 0 15px 0 5px;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .fileInfos .btn {
        border-radius: 100%;
        color: white;
        width: 40px;
        height: 40px;
        min-width: 40px;
        padding: 0;
    }

@keyframes disappear {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.progressCount {
    margin-left: 10px;
}

.uploadMessage {
    margin: 0 0 0 10px;
}

.errorDetails {
    margin: 0;
    opacity: 0.4;
    font-size: 0.8em;
    position: relative;
    top: -0.6em;
}