.icon {
    color: white;
    font-size: 25px;
}

.icon-svg {
    grid-column: 1/2;
    grid-row: 1/2;
    margin: auto;
}

.display-none {
    display: none;
}

.opacity {
    opacity: 0.2;
}

/*Tool-menu*/

.tool-menu-container {
    position: absolute;
    margin-right: auto;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tool-menu-cache {
    background-color: black;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100%;
}

/*ToolBar*/
.tool-bar {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    justify-self: center;
}

    .tool-bar .title {
        grid-row: 1/2;
        color: white;
        opacity: 0.5
    }

    .tool-bar button {
        background: #FFF0;
        border: none;
        width: 2.5em;
        height: 2.5em;
        border-radius: 100%;
        padding: 0;
        margin: 0.2em;
    }

    .tool-bar .selected {
        background: #FFF4;
    }


.tools {
    grid-column: 1/2;
}

.sizes {
    grid-column: 2/3;
}

.colors {
    grid-column: 3/4;
}

.actions {
    grid-row: 2/3;
    display: flex;
    margin: 0px 5px 0px 5px;
    align-items: center;
}

.color-circle {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

    .color-circle .small {
        width: 85%;
        height: 85%;
        border: 4px solid;
        margin: auto;
    }

.size-circle {
    border-radius: 50%;
    margin: auto;
    display: flex;
}

/*Tool palette*/

.tool-palette {
    position: fixed;
    bottom: 0px;
    right: 0px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 0.5fr 4.5fr 1.5fr;
    justify-self: center;
    z-index: 2;
    padding: 10px;
    opacity: 0.98;
}

    .tool-palette .cross {
        color: white;
        opacity: 0.5;
        justify-self: end;
        width: auto;
        height: auto;
        margin: 0px;
    }

    .tool-palette button {
        background: #FFF0;
        border: none;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        padding: 0;
        margin: 5px;
    }

    .tool-palette .selected {
        background: #FFF4;
    }

.color-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-self: center;
}

.size-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-self: center;
}

.tool-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    justify-self: center;
}

.row1 {
    grid-row: 1/2;
}

.row2 {
    grid-row: 2/3;
}

.row3 {
    grid-row: 3/4;
}

.col1 {
    grid-column: 1/2;
}

.col2 {
    grid-column: 2/3;
}

.shown-when-eraser {
    grid-row: 2/3;
    grid-column: 1/3;
    z-index: 3;
}

/*Tool floating button*/
.fab-container {
    display: grid;
    float: right;
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.floating-button {
    grid-column: 1/2;
    grid-row: 2/3;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 2px white;
    z-index: 3;
    padding: initial;
}

.child {
    height: 90px;
    width: 45px;
    grid-row: 1/3;
    border-radius: 45px;
    z-index: 2;
    margin: auto;
    align-content: center;
}

.palette-icon-container {
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    height: 70px;
}
